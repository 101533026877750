//noinspection JSUnusedGlobalSymbols
/**
 * @constructor
 */
export default function SymfonyComponentValidatorConstraintsValid() {
    this.validate = function (value, element) {
        return [];
    };
}

window.SymfonyComponentValidatorConstraintsValid = SymfonyComponentValidatorConstraintsValid;
